import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { AboutHeader, BannerLeft, BannerButton } from "../utils"
// import ReservationForm from "../components/globals/ReservationForm"

import img from "../images/bcg/thanksgiving-pumpkins.jpg"

const ThanksgivingPage = () => (
  <Layout>
    <SEO title="Thanksgiving Day Buffet" keywords={[`Thanksgiving in Clemson, Seneca, and greater Upstate SC`]} />
    <AboutHeader img={img}>
      <BannerLeft title="Thanksgiving Traditions" subtitle="Reservations Encouraged.">
        <hr />
        <p>
          Thanksgiving Day just got a whole lot easier!
        </p>
      </BannerLeft>
    </AboutHeader>

    <section id="special">
      <div className="row px-0 py-5 justify-content-center">
        <div className="col-12 col-md-10 col-lg-9 px-0 py-4 mx-0">
          <h2>Thanksgiving Day Buffet</h2>
          <br />
          <br />
          <p>
           Join us on the lake for a traditional Thanksgiving Day feast. Who wants to cook a huge turkey dinner when you can enjoy a bountiful gourmet buffet with all the fixings? Not a turkey fan? We'll also have main courses of ham, baked chicken, and fried flounder. Reservations are encouraged, please call 864-722-2800.
          </p>
          <br />
          <br />
          <p>
            Date: Thursday, Nov. 28, 2024
            <br />
            Time: 11 a.m. to 2:30 p.m.
            <br />
            <br />
            Adults ~ $29
            <br />
            Seniors (65 and up) ~ $27
            <br />
            Kids (4-12) ~ $17
            <br />
            Kids under 4 eat free
            <br />
            <i>(Beverages not included.)</i>
          </p>

          <br />
          <br />
          <BannerButton>
            <a href="/thanksgiving-menu.pdf">Thanksgiving Buffet Menu</a>
          </BannerButton>
        </div>
      </div>
      <br />
    </section>
  </Layout>
)

export default ThanksgivingPage
